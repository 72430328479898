<template>
    <div>
        <v-row>
            <!-- Mensaje de exito/error -->
            <v-col cols="12">
                <v-alert v-if="!!message" :type="message.type">{{ message.text }}</v-alert>
            </v-col>
            <v-col cols="12" md="4">
                <DashCard title="Seleccionar usuario" title-icon="people">
                    <v-label>Seleccione un usuario para administrar sus permisos de lectura, escritura, entre otros, catalogados en grupos.</v-label>
                    <v-row>
                        <v-col cols="12">
                            <v-select flat solo outlined dense 
                                prepend-inner-icon="account_circle" 
                                label="Seleccione un usuario..." 
                                hide-details
                                clearable
                                v-model="usuario" 
                                :items="usuario_list" 
                                :item-text="(i) => `${i.perfil.first_name} ${i.perfil.last_name}`"
                                return-object
                                @change="update_data" />
                        </v-col>
                        <v-col cols="12" class="pa-0">
                            <PersonaListItem v-model="selected_perfil" />
                            <p class="text--primary px-4" v-if="!!usuario">
                                <b>Grupo perteneciente:</b>
                                <v-chip class="mx-1" v-for="(group, i) in usuario.groups" :key="i">{{ group }}</v-chip>
                            </p>
                        </v-col>
                    </v-row>
                    <v-divider />
                    <div class="py-2">
                        <v-label>
                            Al crear un usuario, a este se le asigna un grupo específico (Administradores o Empleados).
                            <br/><br/>
                            Estos grupos delimitan los accesos visibles del menú lateral a los usuarios de la plataforma, 
                            como también los ajustes que son posibles de realizar en esta configuración.
                        </v-label>
                    </div>
                </DashCard>
            </v-col>
            <v-col cols="12" md="8">
                <DashCard body-class="pa-0" title="Permisos por módulo" title-icon="settings">
                    <v-list dense class="pa-0">
                         <GroupAccess :key="i" v-model="input" :item-model="item" v-for="(item, i) in list_permisos"/>
                    </v-list>
                    <v-overlay absolute color="white" v-show="!usuario" />
                </DashCard>
            </v-col>
            <v-col v-if="!!usuario" cols="12">
                <DashCard :actions="true" actions-align="right">
                    <template v-slot:actions>
                        <v-btn depressed large color="primary" @click="save_changes">
                            <v-icon>done</v-icon>
                            <span class="ml-2 subtitle-2">Guardar cambios</span>
                        </v-btn>
                    </template>
                </DashCard>
            </v-col>
        </v-row>
        <Message ref="dialog" />
    </div>
</template>

<script>
import DashCard from '@/shared/components/DashCard'
import PersonaListItem from '@/dash/components/PersonaListItem'
import GroupAccess from '@/dash/components/GroupAccess'
import Message from '@/shared/components/Message'
import { axiosHelper, messages } from '@/helpers'
import _permisos from '@/assets/user_permissions.json'

export default {
    components: {
        DashCard,
        PersonaListItem,
        GroupAccess,
        Message
    },
    data: () => ({
        permisos: _permisos,
        input: null,
        message: null,
        usuario: null,
        usuario_list: []
    }),
    computed: {
        list_permisos() {
            if(!this.usuario) return this.permisos;

            if(this.usuario.groups.includes('supers')) return this.permisos;

            return this.permisos.filter(x => {
                if(typeof(x.groups) != 'string') {
                    return x.groups.some(y => this.usuario.groups.includes(y));
                } else {
                    return x.groups == "all";
                }
            });
        },
        selected_perfil() {
            if(!this.usuario) return null;
            return this.usuario.perfil || null;
        }
    },
    mounted() {
        axiosHelper.get('usuarios/')
            .then(response => this.usuario_list = response.data.filter(x => !!x.is_active && !x.groups.includes('supers')));

        this.$emit('title', { text: "Permisos de usuarios", icon: "policy" });
    },
    methods: {
        update_data() {    
            this.input = null;
            if(!this.usuario) return;

            axiosHelper.post('mod/access/', { usuario: this.usuario.id })
                .then(response => this.input = response.data);
        },
        save_changes() {
            axiosHelper.put('mod/access/', { usuario: this.usuario.id, access: this.input })
                .then(response => {
                    this.input = response.data;
                    this.$refs.dialog.show(messages.titles["success_generico"], messages.texts["success_access_changed"], "success");
                })
                .catch(error => {
                    this.$refs.dialog.show(messages.titles["error_generico"], messages.texts["error_access_changed"], "error");
                });
        }
    }
}
</script>