<template>
    <v-list-group>
        <template slot="activator">
            <v-list-item-avatar>
                <v-icon color="primary">build</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
                <v-list-item-title class="subtitle-2">{{ itemModel.name }}</v-list-item-title>
            </v-list-item-content>
        </template>
            <v-list-item :key="i" v-for="(sub, i) in itemModel.options">
                <v-list-item-avatar>
                    <v-icon color="info">settings</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title class="subtitle-2" :title="sub.title">{{ sub.title }}</v-list-item-title>
                    <v-list-item-subtitle v-if="sub.subtitle" class="subtitle-2 font-weight-regular" :title="sub.subtitle">{{ sub.subtitle }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action v-if="!!value">
                    <v-switch v-model="value[itemModel.code][sub.code]" :default="false" />
                </v-list-item-action>
            </v-list-item>
    </v-list-group>
</template>

<script>
export default {
    props: ['value', 'item-model'],
    watch: {
        value: function(_new, _old) {
            if(!!_new) {
                if(Object.keys(_new).includes(this.itemModel.code)) {
                    return;
                } else {
                    this.value[this.itemModel.code] = {}
                    this.itemModel.options.forEach(sub => {
                        this.value[this.itemModel.code][sub.code] = false;
                    });
                }
            }
        }
    }
}
</script>